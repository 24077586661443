<template>
  <div class="mt-5">
    <b-container>
      <h2 class="text-primary font-size-20 font-weight-bold mb-5">
        {{$t('main.searchResult')}}
      </h2>
      <b-row v-if="searchLoading">
        <b-col>
          <b-spinner label="Loading..."></b-spinner>
        </b-col>
      </b-row>
      <b-row v-else class="">
        <b-col v-if="!resultList">
          <b-alert show variant="danger">
            <h5 class="font-weight-bold">
              {{$t('main.noResult')}}
            </h5>
          </b-alert>
        </b-col>
        <b-col
          v-else
          sm="12"
          v-for="(item, index) in resultList"
          :key="index"
          class="search-listing d-flex align-items-center gap_1"
          @click="handleSearchItem(item)">
          <img :src="item.image" alt="ibiil img" class="img-fluid" />
          <div class="content d-flex justify-content-between align-items-end flex-fill">
            <div>
              <h4 class="search-listing-result-item-title mb-2">{{item.title}}</h4>
              <h5 class="search-listing-result-item-desc m-0">{{item.table}}</h5>
            </div>
            <div>
              <h6 class="search-listing-result-item-category d-flex align-items-center">
                <span class="t">القسم:</span>
                <span>{{item.module}}</span>
              </h6>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import api from '@/services/main.js'
import commonMixin from '@/mixins/commonMixin'
export default {
  mixins: [commonMixin],
  data () {
    return {
      searchLoading: false,
      resultList: null
    }
  },
  methods: {
    getResult () {
      const queryParams = this.$route.query
      if (Object.keys(queryParams).length && queryParams.search) {
        this.searchLoading = true
        const payload = queryParams.category ? {
          search: queryParams.search,
          modules: [queryParams.category]
        } : {
          search: queryParams.search
        }
        api.getGlobalSearch(payload).then((res) => {
          this.resultList = res.data
        }).finally(() => {
          this.searchLoading = false
        })
      }
    },
    handleSearchItem (item) {
      this.searchList(item)
    }
  },
  watch: {
    '$route.query.search': {
      handler: function (search) {
        this.getResult()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getResult()
  }
}
</script>
<style lang="scss">
.search-listing {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  background-color: #fff;
  border-radius: 10px;
  color: #333;
  padding: 20px;
  cursor: pointer;
  transition: .3s;
  margin-bottom: 20px;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 0 12px rgba(0 0 0 / 30%);
  }
  img {
    width: 100px;
    min-height: 100px;
    border-radius: 10px;
    display: block;
  }
}
</style>
